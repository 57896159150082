import React, { useEffect, useState,useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProgressBar from '@ramonak/react-progress-bar';
import { Container, Row, Col,Modal, Form, Button,Card, Alert, Table,Toast, InputGroup,ToastContainer,Offcanvas } from 'react-bootstrap';
import {BsCopy } from 'react-icons/bs';
import AWS from 'aws-sdk';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import StatusTracker from './StatusTracker';
import { FaArrowLeft } from 'react-icons/fa';
import LiveProjectData from './liveProjectData';

import Tab from 'react-bootstrap/Tab';
import Pagination from "react-bootstrap/Pagination";
import mixpanel from '../mixpanel';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji,getCountryCodeFromCountryName} from 'country-codes-flags-phone-codes';
import { findFlagUrlByCountryName } from "country-flags-svg";
import data from './countries.json'
import Tabs from 'react-bootstrap/Tabs';
import { transliterate as tr } from 'transliteration';
import API_BASE_URL from "../apiConfig";


const s3FileUpload = new AWS.S3({
    correctClockSkew: true,
    endpoint: 'fra1.digitaloceanspaces.com',
    accessKeyId: process.env.REACT_APP_ACCES_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: 'fra1',
    logger: console
});

let lastUploadFile;
let totalPages =1;

const resolutions = [
    { label: '360p (640x360)', value: '360p' },
    { label: '480p (854x480)', value: '480p' },
    { label: '720p (1280x720)', value: '720p' },
    { label: '1080p (1920x1080)', value: '1080p' },
    { label: '1440p (2560x1440)', value: '1440p' },
    { label: '4K (3840x2160)', value: '4K' },
  ];


let STATUS_STEPS = [
    "Queued",
    "Downloading Files",
    "Extracting & Scanning",
    "Saving to Repository",
    "Distributing to Servers",
  ];
const ProjectDetailUser = ({ user, canEdit }) => {
    let intervalId = null;
  
    const [loading, setLoading] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    
           const [key, setKey] = useState('info');
       
      const [invoices, setInvoices] = useState([]);
    
    
    const [minQP,setMinQP]= useState();

    const [maxQP,setMaxQP]= useState();
    const [minBitrate,setMinBitrate]= useState();
    const [maxBitrate,setMaxBitrate]= useState();
    const [startBitrate,setStartBitRate]= useState();
   const [autoDeleteCache,setAutoDeleteCache] = useState(false);
    const [passwordProtection, setPasswordProtection] = useState(false); 
    const [password, setPassword] = useState('');
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState();
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [fileName, setFileName] = useState('');
    const [newProjectName, setNewProjectName] = useState('');
    const [status, setStatus] = useState(false);
    const [showBtn, setShowBtn] = useState(true);
    const [showBtnVolume, setShowBtnVolume] = useState(true);
    const [showBtnInfo, setShowBtnInfo] = useState(false);
    const [showBtnResolution, setShowBtnResolution] = useState(true);
    const [fakeMouseTouch, setFakeMouseTouch] = useState(true);
    const [primaryCodec, setPrimaryCodec] = useState();
    const [fallbaCodec, setFallbaCodec] = useState();
    const [streamLogs,setStreamLogs ] = useState([]);
    const [micEnable, setMicEnable] = useState(false);
    const [mouseInput, setMouseInput] = useState(true);
    const [keyBoardInput, setKeyBoardInput] = useState(true);
    const [touchInput, setTouchInput] = useState(true);
    const [gamepadInput, setGamepadInput] = useState(false);
    const [xrInput, setXrInput] = useState(false);
    const [resolution, setResolution] = useState(true);
    const [hoverMouse, setHoverMouse] = useState(true);
    const [maxRunTime, setMaxRunTime] = useState();
    const [afktimeout, setAfktimeout] = useState();
    const [showFileStatus, setShowFileStatus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showUploadTable, setShowUploadTable] = useState(false);
    const[uploadFileName, setUploadFileName] = useState();
    const[autoRelease, setAutoRelease] = useState(true);
    const[distribute,setDistribute] = useState(false);
    const[validPathUrl,setValidPathUrl] = useState();
    const [showModalUserUpdate, setShowModalUserUpdate] = useState(false);
    const[checkData,setCheckData] = useState(false);
    const [resolutionMode, setResolutionMode] = useState();
    const [maxStreamQuality, setMaxStreamQuality] = useState();
    const [startResolution, setStartResolution] = useState();
    const [startResolutionTab, setStartResolutionTab] = useState();
    const [startResolutionMobile, setStartResolutionMobile] = useState();

    const [region, setRegion] = useState();


    const[ fileUploadStart, setFileUploadStart] = useState(false);

    const storedItemsDist = JSON.parse(localStorage.getItem("fileDataDistribute")) || [];
const projectItem = storedItemsDist.find(item => item.id === projectId);

const [btntoRelease, setBtnToRelease] = useState(projectItem ? true : false);
const [readyFileId, setReadyFileId] = useState(projectItem?.readyFileId || "");

    const rzpRef = useRef();

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;
  
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    
    const navigate = useNavigate();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

     





       const updateStateFromLocalStorage = async() => {
        const storedItemsDist = JSON.parse(localStorage.getItem("fileDataDistribute")) || [];
        const projectItem = storedItemsDist.find(item => item.id === projectId);
    
        if (projectItem) {

            console.log("readyFile:",projectItem.readyFileId);
            setReadyFileId(projectItem.readyFileId);
            setBtnToRelease(true);
            await delay(1000);
          
        } else {
            setReadyFileId("");
            setBtnToRelease(false);
        }
    };

      useEffect(() => {
        let isMounted = true;


        const fetchProjectDetails = async () => {
            try {

                await updateStateFromLocalStorage();
                
                setCheckData(true);

                const responseInvoice = await axios.get(`${API_BASE_URL}/pixelStripeApi/invoices`);

                if (responseInvoice.status === 200) {
                    if(responseInvoice.data.length > 0 ){
                        const projectInvoices = responseInvoice.data.filter(e=> e.projectId == projectId).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                        setInvoices(projectInvoices);

                    }
                }

                const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`);
                if (response.status === 200) {


                    if(response.data.user !== user._id && user.isAdmin == false){
                        handleBackClick();
                        return;
                    }

                    let storedItems = JSON.parse(localStorage.getItem("fileData")) || [];

                    const exists = storedItems.some(item => item.id === projectId);

                    if (exists) {
                         lastUploadFile = response.data.files[response.data.files.length - 1];
                        setShowUploadTable(true);
                    } else {
                    }

         
                    if(response.data.autoRelease == true){
                        STATUS_STEPS=
                            [
                            "Queued",    
                            "Downloading Files",
                            "Extracting & Scanning",
                            "Saving to Repository",
                            "Distributing to Servers",];
                    }



                    let storedItemsDist = JSON.parse(localStorage.getItem("fileDataDistribute")) || [];

                    const existsDist = storedItemsDist.some(item => item.id === projectId);

                    if (existsDist) {

                    
                        var fileId  = storedItemsDist.filter(item => item.id === projectId);


                        var fileObjDist = fileId[0];
              
                        const objFile = response.data.files.filter(
                          (e) => e._id === fileObjDist.readyFileId
                        );

                        var fileObjDistNew = objFile[0];

                        console.log("fileObjDistNew:",fileObjDistNew);

                        setDistribute(true);
                        setReadyFileId(fileId[0].readyFileId);
                        setBtnToRelease(true);

                        if(fileObjDistNew.status == "Approved"){
                            setDistribute(false);
                            setReadyFileId();
                            setBtnToRelease(false);
                      storedItemsDist = storedItemsDist.filter(item => item.id !== projectId);
                      localStorage.setItem("fileDataDistribute", JSON.stringify(storedItemsDist));
                      window.location.reload(true);
                        }
                        
                    } else {
                    }

            
            
                         totalPages = Math.ceil(response.data.files.length / rowsPerPage);


                    if(response.data.autoRelease == false){
                        STATUS_STEPS=
                            [
                            "Queued",    
                            "Downloading Files",
                            "Extracting & Scanning",
                            "Saving to Repository"];
                    }


                    const validPathUrlString = response.data.validPathUrl.join(", ");
                    setValidPathUrl(validPathUrlString);

                    setProjectData(response.data);
                    setNewProjectName(response.data.name);
                    setStatus(response.data.status);
                    setShowBtn(response.data.showBtn);
                    setFakeMouseTouch(response.data.fakeMouseTouch);
                    setShowBtnVolume(response.data.showBtnVolume);
                    setShowBtnInfo(response.data.showBtnInfo);
                    setMinBitrate(response.data.minBitrate);
                    setMaxBitrate(response.data.maxBitrate);
                    setMaxQP(response.data.maxQP);
                    setMinQP(response.data.minQP);
                    setStartBitRate(response.data.startBitrate);
                    setAutoDeleteCache(response.data.autoDeleteCache);
                    setShowBtnResolution(response.data.showBtnResolution);
                    setPrimaryCodec(response.data.primaryCodec);
                    setFallbaCodec(response.data.fallbaCodec);
                    setMicEnable(response.data.showMic);
                    setPasswordProtection(response.data.passwordProtect);
                    setPassword(response.data.password);
                    setAfktimeout(response.data.afktimeout);
                    setMaxRunTime(response.data.maxRunTime);
                    setMouseInput(response.data.mouseInput);
                    setTouchInput(response.data.touchInput);
                    setKeyBoardInput(response.data.keyBoardInput);
                    setResolution(response.data.resolution);
                    setRegion(response.data.region);

                    setHoverMouse(response.data.hoverMouse);
                    setXrInput(response.data.xrInput);
                    setAutoRelease(response.data.autoRelease);
                    setResolutionMode(response.data.resolutionMode);
                    setStartResolution(response.data.startResolution);
                    setStartResolutionTab(response.data.startResolutionTab);

                    setStartResolutionMobile(response.data.startResolutionMobile);

                    setMaxStreamQuality(response.data.maxStreamQuality);

                    setGamepadInput(response.data.gamepadInput);


                    if ( response.data.subscriptionStatus == "cancelled" || response.data.subscriptionStatus == "pending") {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }
    
                    if ( response.data.subscriptionStatus == "created" && response.data.paymentMethodAttached == false) {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }

                    if ( response.data.subscriptionStatus == "active" || response.data.subscriptionStatus == "Scheduled to Cancel" || response.data.subscriptionStatus == "authenticated" || response.data.subscriptionStatus == "freeTrial" || response.data.subscriptionStatus == "freeTrialEnd") {
                        setLoading(false);
                        // Stop fetching details if subscriptionStatus is not 'created'
                        return;
                    }


           


                }
            } catch (error) {
                console.error('Error fetching project details:', error);
            }
    
            if (isMounted) {
                await delay(1500); 
                fetchProjectDetails();
            }
        };
    
        fetchProjectDetails();
    
        return () => {
            isMounted = false; 
        };
    }, [projectId]);



    const handleTransliteration = (fileName1) => {
        try {
            console.log(fileName1);
         
        return tr(fileName1);
        } catch (error) {
          console.error('Error during transliteration:', error);
        }
      };


    const handleFileUpload = async (file) => {

          
        mixpanel.track('Clicked Upload Build Button', {
            "UserId": user._id,
            "Project Id":projectId,
            "Project Name":projectData.name,
            "Autorelease":projectData.autoRelease
          });


        if(projectData.subscriptionStatus == 'active' || projectData.subscriptionStatus == "authenticated" || projectData.subscriptionStatus == "Scheduled to Cancel"){
       
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop().toLowerCase();

        const fileName1 = file.name.replace(".zip", "");

        const transliteratedFileName =   handleTransliteration(fileName1);

        console.log(transliteratedFileName);

        const fileName2 = transliteratedFileName
        .replace(/^\d+/, '')
        .replace(/[^a-zA-Z0-9]/g, '');

        setUploadFileName(fileName2);


        if (file.size > 26843545600) {
            setShowMessage(true);
            setAlertMessage('Invalid file size');
            setAlertVarient('danger');

            mixpanel.track('Build Upload Failed', {
                "UserId": user._id,
                "Project Id":projectId,
                "Reason":"Invalid file size",
                
              });

        } else {
            const extArray = ['.zip'];
            const fileValid = extArray.some(el => file.name.includes(el));
            if (fileValid) {

                setFileUploadStart(true);
                 
    
        setShowProgressBar(true);
                    const currDate = Date.now();
                    let newName = currDate + fileName2 + ".zip";

                    console.log(newName);
                    setFileName(newName.replace(".zip", ""));
                    const newFile = new File([file], newName);
    
                    const startTime = moment();
                    const uploadRequest = new AWS.S3.ManagedUpload({
                        params: { 
                             Bucket:'unreal-engine-sdk',
                            Key:newName, 
                            Body:newFile, 
                            ACL: 'public-read',
                            partSize:  200 * 1024 * 1024,
                            queueSize: 1 }, 
                        service: s3FileUpload
                    });
                    uploadRequest.on("httpUploadProgress", function (evt) {
                        setProgress(parseInt((evt.loaded * 100) / evt.total));
                    });
                    uploadRequest.send(async function (err, response) {
                        if (err) {
                            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));

                            mixpanel.track('Build Upload Failed', {
                                "UserId": user._id,
                                "Project Id":projectId,
                                "Reason":"UPLOAD ERROR SERVER",
                                
                              });

                            
                        } else {
                            if (response.Location !== undefined && response.Location !== null) {
    
                                try {

                              const endTime = moment();

                              const timeTaken = endTime.diff(startTime,'minutes');
    
                                   const responseProject =  await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, { fileUrl: response.Location,
                                        webIp:process.env.REACT_APP_WEBIP,
                                       userId: user._id });

                                       console.log(responseProject);
    
                                       if (responseProject.status === 200) {
                                        setProjectData(responseProject.data);
                                        const fileArray = responseProject.data.files;
                                        const liveFileObj = fileArray[fileArray.length-1];
    

                                        lastUploadFile = liveFileObj;
                                        setShowProgressBar(false);
                                        
                                        setShowFileStatus(true);
                                        setShowUploadTable(true);
                                        setFileUploadStart(false);

                                        let storedItems = JSON.parse(localStorage.getItem("fileData")) || [];

                                        storedItems.push({ id: projectId, showFileStatus: "showFile" });
                                        
                                        localStorage.setItem("fileData", JSON.stringify(storedItems));                                        

                                        mixpanel.track('Build Upload Completed on DigitalOcean', {
                                            "UserId": user._id,
                                            "Project Id":projectId,
                                            "Size":file.size,
                                            "UploadId":liveFileObj._id,
                                            "Time taken":timeTaken
                                          });
                                       }
                                    
                                } catch (error) {
                                    console.log(error);
                                }
                          
                               // window.location.reload(true);
                            }
                        }
                    });
                } else{
    
                    setShowMessage(true);
                    setAlertMessage(`Invalid file type: ${fileExtension}. Only .zip files are allowed.`);
                    setAlertVarient('danger');
    
                    mixpanel.track('Build Upload Failed', {
                        "UserId": user._id,
                        "Project Id":projectId,
                        "Reason":"Invalid file type",
                        
                      });
                }
    
          } 
    }else{
            setShowMessage(true);
            setAlertMessage('Activate Subscripiton First');
            setAlertVarient('danger');
    }};


    const handleFileStatus =() =>{

        setShowUploadTable(false);
        localStorage.setItem("showFileStatus", "hideFile");
        window.location.reload();
    }

    const cancelFileUpload = () => {

        
        mixpanel.track('Build Upload Cancelled', {
            "UserId": user._id,
            "Project Id":projectId,
            "Project Name":projectData.name,
            "Completed":progress
          });

        setShowProgressBar(false);
        window.location.reload(false);
    };

    const copyLink = (link) => {
        console.log(link);
        navigator.clipboard.writeText(link);
        setShowMessage(true);
        setAlertMessage('Link successfully copied to clipboard');
        setAlertVarient('primary');
        
        mixpanel.track('Copy Streaming Link', {
            "UserId": user._id,
            "Project Id":projectId,
          });

    };

    const extractNameFromUrl = (url) => {
 
          if(url){
        const parts = url.split("/");
        const fileName = parts[parts.length - 1];
        const fileName1 = fileName.replace(".zip", "");
        const fileName2 = fileName1.replace(/^\d+/, '');
        return fileName2;
          }
    };

    const renderTableStream = () => {
         
        return  (
         <LiveProjectData projectId={projectId} />
        );
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
   
      const renderTableRows = () => {
       
        const sortedFiles = projectData?.files || [];


    
        const handleActiveFile = async (fileId) => {
    
            
            mixpanel.track('Set Live Build Button', {
                "UserId": user._id,
                "Project Id":projectId,
                "UploadId":fileId
              });

            if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel" || projectData.subscriptionStatus == "Scheduled to Cancel") {

            try {
                const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                    activeFileId: fileId,
                    userId: user._id,
                    webIp: process.env.REACT_APP_WEBIP,
                });
    
                if (response.status === 200) {
                    setReadyFileId(fileId);
                    setBtnToRelease(true);

                    let storedItems = JSON.parse(localStorage.getItem("fileDataDistribute")) || [];

                    storedItems.push({ id: projectId, showFileStatus: "showFile", "readyFileId":fileId });
                    
                    localStorage.setItem("fileDataDistribute", JSON.stringify(storedItems));                

                    setDistribute(true);
                }
            } catch (error) {
                console.error("Error updating project name:", error);
                setShowMessage(true);
                setAlertVarient("danger");
                setAlertMessage("Failed to update project. Please try again.");
            }
        }
        else{
        setShowMessage(true);
        setAlertMessage('Not allowed');
        setAlertVarient('error');
        }
        };
    
        if (!sortedFiles.length) {
            return (
                <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                        No Build Uploaded Yet
                    </td>
                </tr>
            );
        }
    
        const projectFileArray = showUploadTable
        ? [...sortedFiles].slice(0, sortedFiles.length - 1).sort((a, b) => new Date(b.date) - new Date(a.date))
        : [...sortedFiles].sort((a, b) => new Date(b.date) - new Date(a.date));
      
    
        const appFileIndex = projectFileArray.findIndex(file => file.appPath === projectData.appName);
    
        if (appFileIndex !== -1) {
            const [appFile] = projectFileArray.splice(appFileIndex, 1);
            projectFileArray.unshift(appFile);
        }

        
    
        const startIndex = Math.max(0, (currentPage - 1) * rowsPerPage);
        const endIndex = Math.min(projectFileArray.length, startIndex + rowsPerPage);
        const paginatedData = projectFileArray.slice(startIndex, endIndex);
    
        
        return paginatedData.map((file, index) => (
            <tr key={file._id} style={{verticalAlign:"middle"}}>
                <td><p style={{ margin:"auto" }}>{extractNameFromUrl(file.url)}</p></td>
                <td>
                    {file.appPath === projectData.appName  &&  file.status === "Approved"? (
                        <p style={{ color: '#00FF00', margin:"auto"  }}>Current Live Build</p>
                    ) : file.status === "Approved" || file.status === "Ready" ? (
                        btntoRelease && readyFileId === file._id ? (
                            <StatusTracker projectId={projectId} distributeToServer={true} statusSTeps={["Distributing to Servers"]} />
                        ) : (
                            <Button
                                onClick={() => handleActiveFile(file._id)}
                                disabled={fileUploadStart || showUploadTable || distribute}
                            >
                                Set as Live Build
                            </Button>
                        )
                    ) : (
                        <p style={{ color: "red",margin:"auto" }}>Validation Failed</p>
                    )}
                </td>
                <td>
  {projectData.appName.includes(file._id) && file.status === "Approved" ? (
    <p style={{ margin: "auto" }}>Active & Live on Platform</p>
  ) : file.status === "Approved" || file.status === "Ready" ? (
    <p style={{ margin: "auto" }}>Archived, Ready to Use</p>
  ) : (
    <p style={{ color: "red", margin: "auto" }}>{file.objection}</p>
  )}
</td>
                <td>{file.unrealVersion}</td>
                <td>{moment.utc(file.date).local().format("DD-MM-YYYY HH:mm:ss")}</td>
                <td>{file._id}</td>
            </tr>
        ));
    };


    const handleMixPanelMessage = (property,value)=>{
        mixpanel.track(property, {
            "UserId": user._id,
            "Project Id":projectId,
            "Value":value
          });
    }
    
    const handlePrimaryCodec = async (e) => {
        setPrimaryCodec(e.target.value);
    
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                primaryCodec: e.target.value,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP
    
            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Updated Successfully');
                handleMixPanelMessage('Primary Video Codec Set',e.target.value);

            }
        } catch (error) {
            console.error('Error updating project:', error);
        }
        
    
    }
    
    
    const handleFallBackCodec = async (e) => {
        setFallbaCodec(e.target.value);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                fallbaCodec: e.target.value,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP
    
            });
            if (response.status === 200) {
              
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Updated Successfully');
                handleMixPanelMessage('Fallback Video Codec Set',e.target.value);

            }
        } catch (error) {
            console.error('Error updating project:', error);
        }
        
    
    }
    

    const updateProjectName = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                name: newProjectName,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Name Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project Name',newProjectName);
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMinQP = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                minQP: minQP,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project MINQP Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project MinQP',minQP);
            }
        } catch (error) {
            console.error('Error updating project MinQP:', error);
        }
    };

    const updateProjectMaxQP = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                maxQP: maxQP,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project MAXQP Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project MaxQP',maxQP);
            }
        } catch (error) {
            console.error('Error updating project MaxQP:', error);
        }
    };

    const resetQuality = async () => {
        setLoadingUpdate(true);
        setMinQP(21);
        setMaxQP(-1);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                maxQP: -1,
                minQP: 21,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project Reset Quality');
            }
        } catch (error) {
            console.error('Error updating project MaxQP:', error);
        }
    };

    const resetBitrate = async () => {
        setLoadingUpdate(true);
        setStartBitRate(10);
        setMinBitrate(1);
        setMaxBitrate(100)

        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                startBitrate: 10,
                minBitrate: 1,
                maxBitrate:100,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project Reset Bitrate');
            }
        } catch (error) {
            console.error('Error updating project MaxQP:', error);
        }
    };

    
    const updateProjectMaxBitrate = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                maxBitrate: maxBitrate,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project maxBitrate Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project maxBitrate',maxBitrate);
            }
        } catch (error) {
            console.error('Error updating project maxBitrate:', error);
        }
    };

    const updateProjectMinBitrate = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                minBitrate: minBitrate,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project minBitrate Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project minBitrate',minBitrate);
            }
        } catch (error) {
            console.error('Error updating project minBitrate:', error);
        }
    };


    const updateProjectStartBitrate = async () => {
        setLoadingUpdate(true);
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                startBitrate: startBitrate,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project startBitrate Updated Successfully');
                setLoadingUpdate(false);

                handleMixPanelMessage('Update Project startBitrate',startBitrate);
            }
        } catch (error) {
            console.error('Error updating project startBitrate:', error);
        }
    };


    const updateProjectFakeTouch = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                fakeMouseTouch: value, 
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');

                handleMixPanelMessage('Toggle Fake Mouse Touch',value);

            }
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };



    const updateProjectBtn = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                showBtn: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');

                handleMixPanelMessage('Toggle Fullscreen Button Visibility',value);


            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectBtnVolume = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                showBtnVolume: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');

                handleMixPanelMessage('Toggle Volume Button Visibility',value);


            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectBtnInfo = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                showBtnInfo: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Info Button Visibility',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMic = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                showMic: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Mic Settings',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMouseInput = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                mouseInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Mouse Input',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateAutoRelease = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                autoRelease: value, 
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');

                handleMixPanelMessage('Toggle Auto Release',value);


                window.location.reload(true);
            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectKeyBoardInput = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                keyBoardInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Keyboard Input',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectTouchInput = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                touchInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Touch Input',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const updateProjectGameInput = async (value) => {

        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                gamepadInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Gamepad Input',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectXrInput = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                xrInput: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle XR Input',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMouseHover = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                hoverMouse: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Hovering Mouse',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectResolutionShow = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                showBtnResolution: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Toggle Resolution Setting Visibility',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectMaxtime = async () => {
if(maxRunTime > 1 && maxRunTime < 1440){
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                maxRunTime: maxRunTime, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Max Runtime Set',maxRunTime);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    }
    else{
        setShowMessage(true);
        setAlertVarient('error')
        setAlertMessage('Max RunTime value must be in the range of 1 to 1440.');
    }
    };

    const updateProjectAfkime = async () => {
        if(afktimeout> 0 && afktimeout < 7201 ){
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                afktimeout: afktimeout, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Inactivity Timeout Set',afktimeout);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    }else{
        setShowMessage(true);
        setAlertVarient('error')
        setAlertMessage('AFK Timeout value must be in the range of 60 to 7200.');
    }
    };

    const updateProjectResolution = async (value) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                resolution: value, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Settings Updated Successfully');
                handleMixPanelMessage('Resoluton Mode Selected',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };
    
    const updateProjectStatus = async (statusValue) => {

        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                status: statusValue, // Include status in the request
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Status Updated Successfully');
                handleMixPanelMessage('Update Project Status',statusValue);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };

    const updateProjectCache = async (value) => {

        try {
            const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
                autoDeleteCache: value, 
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP

            });
            if (response.status === 200) {
                setProjectData(response.data);
                setShowMessage(true);
                setAlertVarient('success')
                setAlertMessage('Project Auto Delete Cache Updated Successfully');
                handleMixPanelMessage('Update Project Auto Delete Cache',value);

            }
        } catch (error) {
            console.error('Error updating project name:', error);
        }
    };


    const cancelSubscriptionModal = async()=>{
    setShowModal(true);

    }
    const cancelSubscription = async () => {
      try {
         
              const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/projects/cancelSub`, {
                  subId: projectData.subId,
                  userId: user._id,
                  projectId:projectData._id,
                  webIp:process.env.REACT_APP_WEBIP
              });
              if (response.status === 200) {
                setShowModal(false);
                  setProjectData(response.data);
                  setShowMessage(true);
                  setAlertMessage('Subscription Cancelled ');
                 setAlertVarient('success');  
                 await delay(3000);
                 const responseNew = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`);
    
                if (responseNew.status === 200) {
                    setProjectData(response.data);
                    setNewProjectName(response.data.name);
                    setStatus(response.data.status);

                 
                    mixpanel.track('Subscription Cancelled ', {
                        "UserId": user._id,
                        "Project Id":projectId,
                      });


                    window.location.reload(false);
                }
              }
          
      } catch (error) {
          console.error('Error cancelling subscription:', error);
      }
  };
  

  const reSubscribe = async () => {
    try {
        const currentDate = new Date();
        const endDate = new Date(projectData.subscriptionEndDate);
        if (currentDate < endDate) {
            // Update subscription status to 'active'
            const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/projects/resumeSub`, {
                subId: projectData.subId,
                userId: user._id,
                webIp:process.env.REACT_APP_WEBIP
            });
            if (response.status === 200) {
                setProjectData(response.data);
            }
        } else {
            // Handle case if the subscription has already expired
            console.log("Cannot re-subscribe: Subscription already expired");
            // You can show an alert or message here if needed
        }
    } catch (error) {
        console.error('Error re-subscribing:', error);
    }
};

const saveProjectName = async()=>{
    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel" ) {
        updateProjectName();
    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }


}

const handleStatus = async(event)=>{

    console.log(projectData.disableByAdmin);
    if(projectData.disableByAdmin === true && canEdit){
    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel" || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setStatus(event);
        updateProjectStatus(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}else{
    setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
}
}


const handleAutoDeleteCache = async(event)=>{

    console.log(projectData.disableByAdmin);
    if(projectData.disableByAdmin === true  && canEdit){
    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel" || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setAutoDeleteCache(event);
        updateProjectCache(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}else{
    setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
}
}

const updateProjectPasswordStatus = async (passwordProtect)=>{

    try {
        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            passwordProtect: passwordProtect, // Include status in the request
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Project Status Updated Successfully');

            handleMixPanelMessage('Toggle Password Protect',passwordProtect);


        }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
}




const deleteProjectDomain = async () => {
    try {

        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            domainName: "domainName",
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Urls Updated Successfully');
        }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
};

const updateProjectDomain = async () => {
    try {

   
      if(validPathUrl == ""){
       await deleteProjectDomain();
      }else{

        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            validPathUrl: validPathUrl,
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Urls Updated Successfully');

            handleMixPanelMessage('Restrict Stream Access',validPathUrl);

        }
    }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
};



const updateProjectPassword = async () => {
    try {
        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            password: password,
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Password Updated Successfully');
        }
    } catch (error) {
        console.error('Error updating project name:', error);
    }
};

const handlePasswordProtectionToggle =async (event) => {

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel" || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setPasswordProtection(event);
        updateProjectPasswordStatus(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }

    setPasswordProtection(!passwordProtection);
};


const handleFakeTouchShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setFakeMouseTouch(event);
        updateProjectFakeTouch(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleBtnShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setShowBtn(event);
        updateProjectBtn(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleBtnVolumeShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setShowBtnVolume(event);
        updateProjectBtnVolume(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}



const handleBtnInfoShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setShowBtnInfo(event);
        updateProjectBtnInfo(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleMicEnable = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setMicEnable(event);
        updateProjectMic(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}


const handleMouseInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setMouseInput(event);
        updateProjectMouseInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleAutoRelease = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setAutoRelease(event);
        updateAutoRelease(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}


const handleKeyBoardInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setKeyBoardInput(event);
        updateProjectKeyBoardInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleTouchInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setTouchInput(event);
        updateProjectTouchInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleGamepadInputShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setGamepadInput(event);
        updateProjectGameInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleHoverMouse = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setHoverMouse(event);
        updateProjectMouseHover(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}


const handleResolutionShow = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setShowBtnResolution(event);
        updateProjectResolutionShow(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleXr = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setXrInput(event);
        updateProjectXrInput(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleMaxRunTime = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setMaxRunTime(event);
        updateProjectMaxtime(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleAfkTime = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setAfktimeout(event);
        updateProjectAfkime(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}

const handleResolution = async(event)=>{

    if(projectData.subscriptionStatus === 'active' || projectData.subscriptionStatus === 'authenticated' || projectData.subscriptionStatus == "Scheduled to Cancel") {
        setResolution(event);
        updateProjectResolution(event);

    }else{

setShowMessage(true);
setAlertMessage('Not allowed');
setAlertVarient('error');
    }
}




const handleBackClick = () => {
    navigate('/dashboardUser');
};



const handleResolutionMode = async(e)=>{

    setResolutionMode(e.target.value);
    try {
        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            resolutionMode: e.target.value,
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Project Updated Successfully');
        }
    } catch (error) {
        console.error('Error updating project:', error);
    }

}


const handleMaxQuality = async(e)=>{

    let maxPixelsValue;
    const maxResmatch = e.target.value.match(/\((\d+x\d+)\)/);
 
    if (maxResmatch) {
 
    const hwsplit = maxResmatch[1].split('x');
    maxPixelsValue = hwsplit[0]*hwsplit[1];
   
    }
 
 
    let MobilePixelValue;
    let TabPixelValue;
    let DesktopPixelValue;

    const startResmatch = startResolution.match(/\((\d+x\d+)\)/);
 
    if (startResmatch) {
 
    const hwsplit = startResmatch[1].split('x');
    DesktopPixelValue = hwsplit[0]*hwsplit[1];
 
    }

    const startMobileResmatch = startResolutionMobile.match(/\((\d+x\d+)\)/);
 
    if (startMobileResmatch) {
 
    const hwsplit = startMobileResmatch[1].split('x');
    MobilePixelValue = hwsplit[0]*hwsplit[1];
 
    }

    const startTabResmatch = startResolutionTab.match(/\((\d+x\d+)\)/);
 
    if (startTabResmatch) {
 
    const hwsplit = startTabResmatch[1].split('x');
    TabPixelValue = hwsplit[0]*hwsplit[1];
 
    }

    if(maxPixelsValue >= MobilePixelValue && maxPixelsValue >= DesktopPixelValue && maxPixelsValue >= TabPixelValue ){


    setMaxStreamQuality(e.target.value);
    try {
        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            maxStreamQuality: e.target.value,
            userId: user._id,
            webIp:process.env.REACT_APP_WEBIP

        });
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success')
            setAlertMessage('Project Updated Successfully');

            handleMixPanelMessage('Max Stream Quality Set',e.target.value);

        }
    } catch (error) {
        console.error('Error updating project:', error);
    }
    }else{
        setShowMessage(true);
        setAlertVarient('error');
        setAlertMessage('The max stream quality value should be greater than or equal to the start resolution.');
    }

}


const handleStartResolution = async (e, type) => {

    
   let maxPixelsValue;
   const maxResmatch = maxStreamQuality.match(/\((\d+x\d+)\)/);

   if (maxResmatch) {

   const hwsplit = maxResmatch[1].split('x');
   maxPixelsValue = hwsplit[0]*hwsplit[1];
  
   }


   let pixelValue;

   const startResmatch = e.target.value.match(/\((\d+x\d+)\)/);

   if (startResmatch) {

   const hwsplit = startResmatch[1].split('x');
   pixelValue = hwsplit[0]*hwsplit[1];

   }

   let deviceSend;
   if(pixelValue <= maxPixelsValue){

    let resolutionKey;
    switch (type) {
        case 'Desktop':
            resolutionKey = 'startResolution';
            setStartResolution(e.target.value);
            deviceSend = "Desktop";
            break;
        case 'Tab':
            resolutionKey = 'startResolutionTab';
            setStartResolutionTab(e.target.value);
            deviceSend = "Tab";
            break;
        case 'Mobile':
            resolutionKey = 'startResolutionMobile';
            setStartResolutionMobile(e.target.value);
            deviceSend = "Mobile";
            break;
        default:
            console.error('Unknown device type');
            return;
    }


    console.log("MAX:",maxPixelsValue);
    console.log("CURR:",pixelValue);
 



    try {
        const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
            [resolutionKey]: e.target.value,
            userId: user._id,
            webIp: process.env.REACT_APP_WEBIP
        });

   
       
        if (response.status === 200) {
            setProjectData(response.data);
            setShowMessage(true);
            setAlertVarient('success');
            setAlertMessage('Project Updated Successfully');

            
            mixpanel.track('Start Resolution Set', {
                "UserId": user._id,
                "Project Id":projectId,
                "Value":e.target.value,
                "Device":deviceSend
              });
        }
    } catch (error) {
        console.error('Error updating project:', error);
    }
}else{
    setShowMessage(true);
    setAlertVarient('error');
    setAlertMessage('The start resolution value should be less than or equal to the max stream quality.');
}
};




    return (
        <Container>

{loading && (projectData && (projectData.subscriptionStatus === 'created' || projectData.subscriptionStatus === 'paymentProcess')) && (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <p style={{ marginTop: '10px' }}>Your payment is being processed. This won't take long. <br /> Please do not close the page.</p>
    </div>
)}

<ToastContainer position={'bottom-end'} style={{position:'fixed',zIndex:20000000000}}>
<Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={60000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>
{!loading &&  checkData &&  (
    <>
<Button  variant="primary" onClick={handleBackClick}>
                        <FaArrowLeft /> Back 
                    </Button>
                    <br/>
                    <br/>




<Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
     <Tab eventKey="info" title="Project Info">
     <Row>
                    <Col md={2}>
            <h5 style={{marginTop:"12px"}}>Project Details:</h5>
             </Col>

             <Col md={{ span: 2, offset:3}} >



</Col>


<Col md={{ span: 2}} >
            
            <Form.Group >
                       <Form.Label>Region</Form.Label>
                       <p>{region}</p>
                   </Form.Group>

            </Col>


             <Col md={{ span: 1, offset:2}} >
            
             <Form.Group >
                        <Form.Label>Status</Form.Label>
                        <Form.Check
                            type="switch"
                            disabled={!canEdit}
                            id="custom-switch"
                            label={status ? 'Active' : 'Inactive'}
                            checked={status}
                            onChange={(e) => handleStatus(e.target.checked)}
                        />
                    </Form.Group>

             </Col>

            </Row>

<br/>
            <Row className="mb-3">
                <Col md={4}>
                <Form.Group className="mb-3">
    <Form.Label>Project Name</Form.Label>
    <InputGroup>
        <Form.Control
         disabled={!canEdit}
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Enter project name"
        />
        <Button  disabled={!canEdit} variant="primary" onClick={saveProjectName}>
            Save
        </Button>
    </InputGroup>
</Form.Group>
                </Col>
                <Col md={4}>
                {projectData && (
    <Form.Group >
        <Form.Label>Share Link</Form.Label>
        <>
            {!projectData.appName ? (
                <Form.Control value="Upload and await approval for share link." readOnly />
            ) : (<> 

                <InputGroup >
                <Form.Control value={`https://share.streampixel.io/${projectData._id}`} readOnly />

        <InputGroup.Text > 
        <BsCopy onClick={()=>copyLink(`https://share.streampixel.io/${projectData._id}`)}/>

        </InputGroup.Text>
      </InputGroup>

              
                </>
            )}
        </>
    </Form.Group>
)}
                </Col>


                <Col md = {4}>
                    <Form.Group>
                        <Form.Label>No. of concurrent streams available</Form.Label>
                        <Form.Control value={projectData ? projectData.allowedUsers : 2} readOnly />
                    </Form.Group>
                </Col>
               

                
            </Row>

<Row className="mb-3">
<h5>Build Details:</h5>

</Row>


<Row className="mb-3">

<Col md={1}>


<Form.Group style={{display:"flex",columnGap:"20px"}}>
 <Form.Label style={{marginTop:"1px"}}>Autorelease</Form.Label>
 <Form.Check
     type="switch"
     disabled={!canEdit}
     id="custom-switch"
     label={autoRelease ? 'Enabled' : 'Disabled'}
     checked={autoRelease}
     onChange={(e) => handleAutoRelease(e.target.checked)}
 />
</Form.Group>

</Col>





<Col md={{ span: 2, offset:9}}>

<Form.Group className="mb-3" style={{textAlign:"end"}}>

{fileUploadStart == false && showUploadTable == false && distribute == false  &&  canEdit ?(
<Button variant="primary" onClick={() => document.getElementById('input-file').click()}>
Upload New Build
</Button>
):(null)}


<Form.Control
type="file"
id="input-file"
className="visually-hidden"
onChange={(e) => handleFileUpload(e.target.files[0])}
/>

</Form.Group>

</Col>

</Row>
            
                <>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th style={{ borderRight: 'none' }}>Name</th>
                        <th style={{ border: 'none' }}>Status</th>
                        <th style={{ border: 'none' }}>Remarks</th>
                        <th style={{ border: 'none' }}>UE Version</th>
                        <th style={{ border: 'none' }}>Date</th>
                       
                        <th style={{ borderLeft: 'none' }}>Upload Id</th>
                    </tr>
                </thead>
                <tbody>
                {showProgressBar == true && (
 <tr style={{verticalAlign:"middle"}}>
 <td>{uploadFileName}</td>
 <td >Uploading</td>
 <td  >
<div style={{display:'flex',gap:"10px"}}>
    <div style={{width:"350px",marginTop:"6px"}}>
    <ProgressBar completed={progress} maxCompleted={100} bgColor="#26F8FF"/>       

    </div>
 <Button onClick={cancelFileUpload} style={{borderRadius:"50px",fontSize:"12px",backgroundColor:"red",borderColor:"red"}}>X</Button>
 </div>
 </td>
 <td ></td>
 <td > </td>
 <td ></td>

</tr> 

                )}
                {showUploadTable == true && (
            <tr style={{verticalAlign:"middle"}}>
                <td >{extractNameFromUrl(lastUploadFile && lastUploadFile.url)}</td>
                <td > 
                <StatusTracker projectId={projectId}  distributeToServer={false} statusSTeps={STATUS_STEPS}/>
                </td>
                <td >{lastUploadFile && lastUploadFile.objection}</td>
                <td>{lastUploadFile && lastUploadFile.unrealVersion}</td>
                <td >{lastUploadFile && moment.utc(lastUploadFile.date).local().format("DD-MM-YYYY HH:mm:ss")}</td>
                
                <td >{lastUploadFile && lastUploadFile._id}</td>
               
              
            </tr>
            )}

                    {renderTableRows()}
                </tbody>
            </Table>
<div className="d-flex justify-content-center mt-3">
            <Pagination style={{textAlign:'center'}}>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
      </div>
</>
    
    <br/>
   



    {projectData && (projectData.subscriptionStatus !== 'created') && (
              <>
            <h5>Subscription Details:</h5>
<>
{ projectData && projectData.subscriptionStatus == "freeTrialEnd" ?(

    <>
    <h6>
    Your free trial period has ended. Please upgrade your subscription to continue.
    </h6>
    </>
):


(

            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Status</Form.Label>
                        {projectData && projectData.subscriptionStatus == "authenticated" ?(
     <Form.Control
     value="Free Trial"
 />
                        ):(
                            <Form.Control
                            value={projectData && projectData.subscriptionStatus}
                        />
                        )}
                   
                    </Form.Group>
                </Col>

                <>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Start Date</Form.Label>
                        
                        <Form.Control
                            value={moment(projectData && projectData.subscriptionStartDate).format("DD-MM-YYYY")}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription End Date</Form.Label>
                        <Form.Control
                            value={moment(projectData && projectData.subscriptionEndDate).format("DD-MM-YYYY")}
                        />
                    </Form.Group>
                </Col>
                </>
               
          
            </Row>

                    )}
                    </>
            </>
             )}
         
<br/>
                             
         {projectData.subscriptionStatus == "created" &&(
<>

<>
            <h5>Subscription Details:</h5>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Status</Form.Label>
                        
                            <Form.Control
                            value={"Payment method not attached"}
                        />
                       
                   
                    </Form.Group>
                </Col>

                <>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription Start Date</Form.Label>
                        
                        <Form.Control
                         value={"--/--/----"}

                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Subscription End Date</Form.Label>
                        <Form.Control
                            value={"--/--/----"}
                        />
                    </Form.Group>
                </Col>
                </>
               
          
            </Row>
            </>
            <br/>

  </>                  )}




     </Tab>

     <Tab eventKey="settings" title="Settings">
     <h5>Access Control:</h5>

<Row>


<Col md={{ span: 4}} >

<Form.Group >
     <Form.Label >
         Password Protect
     </Form.Label>

     <Form.Check
     disabled={!canEdit}
     type="switch"
     id="custom-switch"
     label={passwordProtection ? 'Enabled' : 'Disabled'}
     checked={passwordProtection}
     onChange={(e) => handlePasswordProtectionToggle(e.target.checked)}
 />
   
 </Form.Group>
</Col>

<Col md={{ span: 4}} >

 {passwordProtection && (

<Form.Group >
<Form.Label>Password</Form.Label>
<InputGroup>
<Form.Control
value={password}
onChange={(e) => setPassword(e.target.value)}
placeholder="Enter your password"
/>
<Button variant="primary" onClick={updateProjectPassword}>
Save
</Button>
</InputGroup>
</Form.Group>


 )}
 </Col>

</Row>

<br/>

<Row>

<Col md ={{span:8}}>
<Form.Group className="mb-3">
<Form.Label> Restrict Stream Access to Specific Domains: </Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={validPathUrl}
 onChange={(e) => setValidPathUrl(e.target.value)}
 placeholder="Currently accessible everywhere. Add domains to restrict access (e.g., example.com, test.com)"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectDomain}>
 Save
</Button>
</InputGroup>
</Form.Group>
</Col>

</Row>

 <h5 style={{marginTop:"32px"}}>Frontend Settings:</h5>

<Row>

<Col md={{ span: 2}} >

<Form.Group >
 <Form.Label>Show Fullscreen Button</Form.Label>
 <Form.Check
     type="switch"
     disabled={!canEdit}
     id="custom-switch"
     label={showBtn ? 'show' : 'hide'}
     checked={showBtn}
     onChange={(e) => handleBtnShow(e.target.checked)}
 />
</Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
 <Form.Label>Fake Mouse Touch</Form.Label>
 <Form.Check
     type="switch"
     disabled={!canEdit}
     id="custom-switch"
     label={fakeMouseTouch ? 'Enabled' : 'Disabled'}
     checked={fakeMouseTouch}
     onChange={(e) => handleFakeTouchShow(e.target.checked)}
 />
</Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
 <Form.Label>Show Volume Button</Form.Label>
 <Form.Check
     type="switch"
     disabled={!canEdit}
     id="custom-switch"
     label={showBtnVolume ? 'show' : 'hide'}
     checked={showBtnVolume}
     onChange={(e) => handleBtnVolumeShow(e.target.checked)}
 />
</Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
 <Form.Label>Show Info Button</Form.Label>
 <Form.Check
     type="switch"
     id="custom-switch"
     disabled={!canEdit}
     label={showBtnInfo ? 'show' : 'hide'}
     checked={showBtnInfo}
     onChange={(e) => handleBtnInfoShow(e.target.checked)}
 />
</Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Mic Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={micEnable ? 'Enabled' : 'Disabled'}
    checked={micEnable}
    onChange={(e) => handleMicEnable(e.target.checked)}
/>
</Form.Group>

</Col>



<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Mouse Input Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={mouseInput ? 'Enabled' : 'Disabled'}
    checked={mouseInput}
    onChange={(e) => handleMouseInputShow(e.target.checked)}
/>
</Form.Group>

</Col>
</Row>
<br/>
<Row>

<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>KeyBoard Input Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={keyBoardInput ? 'Enabled' : 'Disabled'}
    checked={keyBoardInput}
    onChange={(e) => handleKeyBoardInputShow(e.target.checked)}
/>
</Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Touch Input Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={touchInput ? 'Enabled' : 'Disabled'}
    checked={touchInput}
    onChange={(e) => handleTouchInputShow(e.target.checked)}
/>
</Form.Group>

</Col>

<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Gamepad Input Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={gamepadInput ? 'Enabled' : 'Disabled'}
    checked={gamepadInput}
    onChange={(e) => handleGamepadInputShow(e.target.checked)}
/>
</Form.Group>

</Col>




<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Xr Input Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={xrInput ? 'Enabled' : 'Disabled'}
    checked={xrInput}
    onChange={(e) => handleXr(e.target.checked)}
/>
</Form.Group>

</Col>



<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Hovering Mouse Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={hoverMouse ? 'Enabled' : 'Disabled'}
    checked={hoverMouse}
    onChange={(e) => handleHoverMouse(e.target.checked)}
/>
</Form.Group>

</Col>


<Col md={{ span: 2}} >

<Form.Group >
<Form.Label>Show Resolution Setting</Form.Label>
<Form.Check
    type="switch"
    id="custom-switch"
    disabled={!canEdit}
    label={showBtnResolution ? 'Enabled' : 'Disabled'}
    checked={showBtnResolution}
    onChange={(e) => handleResolutionShow(e.target.checked)}
/>
</Form.Group>

</Col>

</Row>

<br/>
<br/>
<Row>

<h5>Runtime Configuration:</h5>

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label>Max RunTime (Min. 1 Max 1440 Minutes) </Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={maxRunTime}
 onChange={(e) => setMaxRunTime(e.target.value)}
 placeholder="Max runtime"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectMaxtime}>
 Save
</Button>
</InputGroup>
</Form.Group>
</Col>


<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label>Inactivity Timeout (Min. 1 Max 7200 Seconds) </Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={afktimeout}
 onChange={(e) => setAfktimeout(e.target.value)}
 placeholder="AFK Timeout"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectAfkime}>
 Save
</Button>
</InputGroup>
</Form.Group>
</Col>

</Row>

<br/>

<Row >

<h5>Resolution Settings:</h5>

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Resolution Mode </Form.Label>

<Form.Control
 disabled={!canEdit}
        as="select"
        value={resolutionMode}
        onChange={(e) => handleResolutionMode(e)}
    >
        <option>Dynamic Resolution Mode</option>
        <option>Fixed Resolution Mode</option>
        <option>Crop on Resize Mode</option>
      </Form.Control>
      
</Form.Group>
</Col>


<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Max Stream Quality </Form.Label>

<Form.Control
 disabled={!canEdit}
        as="select"
        value={maxStreamQuality}
        onChange={(e) => handleMaxQuality(e)}
            >
        <option>360p (640x360)</option>
        <option>480p (854x480)</option>
        <option>720p (1280x720)</option>
        <option>1080p (1920x1080)</option>
        <option>1440p (2560x1440)</option>
        <option>4K (3840x2160)</option>
      </Form.Control>
      
</Form.Group>
</Col>


</Row>

<Row >

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Start Resolution Desktops </Form.Label>

<Form.Control
 disabled={!canEdit}
        as="select"
        
        value={startResolution}
        onChange={(e) => handleStartResolution(e,"Desktop")}
    >
        <option>360p (640x360)</option>
        <option>480p (854x480)</option>
        <option>720p (1280x720)</option>
        <option>1080p (1920x1080)</option>
        <option>1440p (2560x1440)</option>
        <option>4K (3840x2160)</option>
      </Form.Control>
      
</Form.Group>
</Col>

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Start Resolution Tablets </Form.Label>

<Form.Control
 disabled={!canEdit}
        as="select"
        value={startResolutionTab}
        onChange={(e) => handleStartResolution(e,"Tab")}
    >
        <option>360p (640x360)</option>
        <option>480p (854x480)</option>
        <option>720p (1280x720)</option>
        <option>1080p (1920x1080)</option>
        <option>1440p (2560x1440)</option>
        <option>4K (3840x2160)</option>
      </Form.Control>
      
</Form.Group>
</Col>

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Start Resolution Mobiles </Form.Label>

<Form.Control
        as="select"
        disabled={!canEdit}
        value={startResolutionMobile}
        onChange={(e) => handleStartResolution(e,"Mobile")}
    >
        <option>360p (640x360)</option>
        <option>480p (854x480)</option>
        <option>720p (1280x720)</option>
        <option>1080p (1920x1080)</option>
        <option>1440p (2560x1440)</option>
        <option>4K (3840x2160)</option>
      </Form.Control>
      
</Form.Group>
</Col>

</Row>
<br/>
<Row>

<h5>Video Settings:</h5>

<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Primary Video Codec </Form.Label>

<Form.Control
 disabled={!canEdit}
        as="select"
        value={primaryCodec}
        onChange={(e) => handlePrimaryCodec(e)}
    >
        <option>AV1</option>
        <option>H264</option>
        <option>VP8</option>
        <option>VP9</option>
      </Form.Control>
      
</Form.Group>
</Col>


<Col md ={{span:4}}>
<Form.Group className="mb-3">
<Form.Label> Fallback Video Codec </Form.Label>

<Form.Control
        as="select"
        disabled={!canEdit}
        value={fallbaCodec}
        onChange={(e) => handleFallBackCodec(e)}
    >
        <option>AV1</option>
        <option>H264</option>
        <option>VP8</option>
        <option>VP9</option>
      
      </Form.Control>
      
</Form.Group>
</Col>

</Row>
<br/>
<br/>
<Row >


<h5> Quality Settings:</h5>
<p>(Changing these may negatively affect stream stability)</p>

<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Min QP</Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={minQP}
 onChange={(e) => setMinQP(e.target.value)}
 placeholder="Min QP"
/>
<Button   disabled={!canEdit} variant="primary" onClick={updateProjectMinQP}>
 Save
</Button>
</InputGroup>
</Form.Group>

</Col>

<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Max QP</Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={maxQP}
 onChange={(e) => setMaxQP(e.target.value)}
 placeholder="Max QP"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectMaxQP}>
 Save
</Button>
</InputGroup>
</Form.Group>

</Col>

{(projectData.minQP !== 21 || projectData.maxQP !== -1) && (
  <Col md={{ span: 2 }}>
    <Form.Group className="mb-3">
      <Form.Label></Form.Label>
      <InputGroup>
        <Button  disabled={!canEdit} variant="primary" onClick={resetQuality} style={{ marginTop: "8px" }}>
          Reset To Default
        </Button>
      </InputGroup>
    </Form.Group>
  </Col>
)}


</Row>


<br/><br/>
<Row>

<h5>Bitrate Settings:</h5>
<p>(Changing these may negatively affect stream stability)</p>

<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Min Bitrate (Mbps)</Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={minBitrate}
 onChange={(e) => setMinBitrate(e.target.value)}
 placeholder="Min Bitrate"
/>
<Button   disabled={!canEdit} variant="primary" onClick={updateProjectMinBitrate}>
 Save
</Button>
</InputGroup>
</Form.Group>

</Col>

<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Start Bitrate (Mbps)</Form.Label>
<InputGroup>
<Form.Control
 value={startBitrate}
 disabled={!canEdit}
 onChange={(e) => setStartBitRate(e.target.value)}
 placeholder="Start Bitrate"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectStartBitrate}>
 Save
</Button>
</InputGroup>
</Form.Group>

</Col>

<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Max Bitrate (Mbps)</Form.Label>
<InputGroup>
<Form.Control
 value={maxBitrate}
 disabled={!canEdit}
 onChange={(e) => setMaxBitrate(e.target.value)}
 placeholder="Max Bitrate"
/>
<Button  disabled={!canEdit} variant="primary" onClick={updateProjectMaxBitrate}>
 Save
</Button>
</InputGroup>
</Form.Group>

</Col>
{(projectData.minBitrate !== 1 || projectData.startBitrate !== 10 || projectData.maxBitrate !== 100) &&(
<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label> 
</Form.Label>
<InputGroup>

<Button  disabled={!canEdit}  variant="primary" onClick={resetBitrate} style={{marginTop:"8px"}}>
Reset To Default 
</Button>
</InputGroup>

</Form.Group>

</Col>
)}
</Row>

<br/>

{projectData.addLaunchArg&&(
<Col md ={{span:2}}>

<Form.Group className="mb-3">
<Form.Label>Additional launch Argument</Form.Label>
<InputGroup>
<Form.Control
 disabled={!canEdit}
 value={projectData.addLaunchArg}
/>

</InputGroup>
</Form.Group>

</Col>
)}



<br/>
<Row>

    <h5>Other Settings:</h5>
<Col md={{ span: 2}} >
            
            <Form.Group >
                       <Form.Label> Auto Delete Cache</Form.Label>
                       <Form.Check
                           type="switch"
                           id="custom-switch"
                           disabled={!canEdit}
                           label={autoDeleteCache ? 'True' : 'False'}
                           checked={autoDeleteCache}
                           onChange={(e) => handleAutoDeleteCache(e.target.checked)}
                       />
                   </Form.Group>

            </Col>

</Row>
    </Tab>


    <Tab eventKey="paymentHistory" title="Payment History">
    

    <h5>Payment History:</h5>
  

<Table striped bordered hover variant="dark">
    <thead>
        <tr>
        <th style={{ borderRight: 'none' }}>Date</th>
            <th style={{ border: 'none' }}>Payment Id</th>
            <th style={{ border: 'none' }}>Amount Paid</th>
            <th style={{ border: 'none' }}>Invoice</th>
        </tr>
    </thead>
    <tbody>
              {invoices.length>0?(
                <>
                {invoices.map((invoice, index) => (
                    <tr key={index}>
                        <td>{moment(invoice.createdAt).format("DD MMMM YYYY")}</td>
                        <td>{invoice.paymentId}</td>
                        <td>{invoice.amount} {invoice.currency}</td>
                        <td> <a href={invoice.invoiceUrl} target="_blank" rel="noopener noreferrer">
          View Invoice
        </a></td>
                    </tr>
                ))}
                </>):(
<>

<tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                    No Payment History Found
                    </td>
                </tr>

</>
                )}
            </tbody>
   
</Table>

    
    </Tab>
    
    
    <Tab eventKey="analytics" title="Analytics (Beta)">
    

    <h5>Streaming Details:</h5>


        {renderTableStream()}
 

<br/>
{streamLogs.length > 0?(
    <>
    <br/>
                  <h5>Player Streaming Logs:</h5>

<Table striped bordered hover variant="dark">
    <thead>
        <tr>
            <th style={{ borderRight: 'none' }}>Player IpAddress</th>
            <th style={{ border: 'none' }}>Player Location</th>
            <th style={{ border: 'none' }}>Start Time</th>
            <th style={{ border: 'none' }}>End Time</th>
            <th style={{ border: 'none' }}>Duration(Minutes)</th>
        </tr>
    </thead>
    <tbody>
    
                {streamLogs.map((log, index) => (
                    <tr key={index}>
                        <td>{log.playerIp}</td>
                        <td>{log.location}</td>
                        <td>{log.startTime}</td>
                        <td>{log.endTime}</td>
                        <td>{log.duration}</td>
                    </tr>
                ))}
            </tbody>
   
</Table>
</>):(null)}
    
    </Tab>






    </Tabs>


                  

<br/>



<br/>

             
        
                </>
)}
        </Container>

    );
}

export default ProjectDetailUser;
