import React, { useState } from 'react';
import { Row, Col, Form, Button, InputGroup, Alert } from 'react-bootstrap';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const LoadingText = ({ projectId, userId, textOne, textTwo, textThree }) => {
  const [loadingTextOne, setLoadingTextOne] = useState(textOne);
  const [loadingTextTwo, setLoadingTextTwo] = useState(textTwo);
  const [loadingTextThree, setLoadingTextThree] = useState(textThree);
  const [error, setError] = useState(null); 
  const [isLoading, setIsLoading] = useState(false); 

  const saveLoadingText = async (row, text) => {

    try {
      setIsLoading(true);
      const response = await axios.put(`${API_BASE_URL}/pixelStripeApi/projects/${projectId}`, {
        [`loadingText${row}`]: text,
        userId: userId,
        webIp: process.env.REACT_APP_WEBIP,
      });
      if (response.status === 200) {
        window.location.reload(true);
        setError(null); 
      }
    } catch (error) {
      console.error('Error updating project:', error);
      setError('Failed to update loading text. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async (row, defaultText) => {
    await saveLoadingText(row, defaultText);
  };

  return (
    <Row>
      <Col md={12}>
        {error && <Alert variant="danger">{error}</Alert>} 
        <div>
          <div className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                value={loadingTextOne}
                onChange={(e) => setLoadingTextOne(e.target.value)}
              />
              <Button 
                variant="primary" 
                onClick={() => saveLoadingText('One', loadingTextOne)}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
              <Button 
                variant="secondary" 
                onClick={() => handleReset('One', "Starting connection to Streampixel server, please wait")}
                disabled={isLoading}
              >
                Reset to Default
              </Button>
            </InputGroup>
          </div>

          <div className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                value={loadingTextTwo}
                onChange={(e) => setLoadingTextTwo(e.target.value)}
              />
              <Button 
                variant="primary" 
                onClick={() => saveLoadingText('Two', loadingTextTwo)}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
              <Button 
                variant="secondary" 
                onClick={() => handleReset('Two', "Almost there, hold tight- awesomeness loading")}
                disabled={isLoading}
              >
                Reset to Default
              </Button>
            </InputGroup>
          </div>

          <div className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                value={loadingTextThree}
                onChange={(e) => setLoadingTextThree(e.target.value)}
              />
              <Button 
                variant="primary" 
                onClick={() => saveLoadingText('Three', loadingTextThree)}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
              <Button 
                variant="secondary" 
                onClick={() => handleReset('Three', "Sharpening pixels and buffing the details...")}
                disabled={isLoading}
              >
                Reset to Default
              </Button>
            </InputGroup>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default LoadingText;
