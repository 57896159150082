import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Container, Nav,Toast,ToastContainer } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';
import API_BASE_URL from "../apiConfig";
import axios from 'axios';

const Header = ({ user, setUser , userTeam}) => {
    const navigate = useNavigate();
        const [alertMessage, setAlertMessage] = useState();
        const [alertVarient, setAlertVarient] = useState();
        const [showDropdown, setShowDropdown] = useState(false);
        const [showMessage, setShowMessage] = useState(false);
    
    const dropdownRef = useRef(null);

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userId');
        setUser(null);
        navigate('/');
        setShowDropdown(false);
        window.location.reload();
    };

    const requestApi = async() => {
      try{

            const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/users/requestApiKey/${user._id}`);

                   if (response.status === 200) {
                    setShowMessage(true);
                    setAlertMessage('The API key has been sent to your email. Please check.');
                    setAlertVarient('primary');

                            }

      }catch(err){
console.log(err);

        setShowMessage(true);
        setAlertMessage('Pleae try later');
        setAlertVarient('danger');

      }
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getInitials = (name) => {
        return name ? name.charAt(0).toUpperCase() : '';
    };

    return (<>
    
    
    
    <ToastContainer position={'bottom-end'} style={{position:'fixed',zIndex:20000000000}}>
                <Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={60000} autohide>
                          <Toast.Header>
                           
                    
                          </Toast.Header>
                          <Toast.Body>{alertMessage}</Toast.Body>
                        </Toast>
                </ToastContainer>

        <Navbar expand="lg" style={{ position: "fixed", top: 0, height: "70px", width: "100%", backgroundColor: "#161616", zIndex: "100" }}>
            <Container style={{ marginTop: "20px" }}>

        

                <Navbar.Brand className="navbar-brand-custom" as={Link} to={user ? "/dashboard" : "/"}>
                    <h3 width="60%" style={{ fontFamily: "Comfortaa-Light" }}>Streampixel</h3>
                </Navbar.Brand>
                {user && (
                    <Nav className="ml-auto d-flex navbar-avatar" style={{ position: 'relative', textAlign: 'end' }}>
                        <Stack direction="horizontal" gap={3}>
                      
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#007bff',
                                    color: '#fff',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    textTransform: 'uppercase'
                                }}
                                onClick={toggleDropdown}
                            >
                                {getInitials(user.name)}
                            </div>
                            <p
                                style={{
                                    fontFamily: "Comfortaa-Light",
                                    marginTop: "20px",
                                    cursor: 'pointer'
                                }}
                                onClick={toggleDropdown}
                            >
                                {user.name}
                            </p>
                        </Stack>
                        {showDropdown && (
                            <div
                                ref={dropdownRef}
                                style={{
                                    position: 'absolute',
                                    top: '50px',
                                    right: '0',
                                    width:"140px",
                                    backgroundColor: '#000',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    zIndex: 1,
                                    padding: '10px',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <div onClick={handleLogout} style={{ cursor: 'pointer', color: '#fff' }}>Logout</div>
  <div onClick={requestApi} style={{ cursor: 'pointer', color: '#fff' }}>Request Api Key</div>

                                {!userTeam &&( 
                        <Nav.Link href="/manageTeam">Manage Team</Nav.Link>
                       )}
                       
                            </div>
                        )}
                    </Nav>
                )}
            </Container>
        </Navbar>
        </>
    );
};

export default Header;