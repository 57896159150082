import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Row, Col, Container,Modal,Toast,ToastContainer} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDeleteOutline } from "react-icons/md";
import mixpanel from '../mixpanel';
import API_BASE_URL from "../apiConfig";

const Dashboard = ({ user, userCountry }) => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [showMessageAlert, setShowMessageAlert] = useState(false);


    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    useEffect(() => {
        const fetchProjects = async () => {
            try {

                mixpanel.identify(user._id);
 
                mixpanel.people.set({ '$name':user.name,
                                      '$email': user.email,
                });
                

        
let response;

                 if(user.isAdmin == true){
                 response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects`);
                 }else{
                    response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/user/${user._id}`);

                 }

                const projectsWithBlank = [{}, ...response.data];
                setProjects(projectsWithBlank);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, [user]);

    const handleNewProject = () => {
        navigate("/createProject");
    };

  

    const handleFreeTrialProject = () => {
        navigate("/createProjectTrial");
    };

    const viewItem = (itemId) => {
        mixpanel.track('Clicked Project Details Button', {
            "UserId": user._id,
            "Project Id":itemId,
            
          });


        navigate(`/projectDetail/${itemId}`);
    };

    const deleteItem = async(project) =>{
 
        if(project.subscriptionStatus == "cancelled" || project.subscriptionStatus == "created" || project.subscriptionStatus == "expired"){

            try {
                const res = await axios.delete(`${API_BASE_URL}/pixelStripeApi/projects/${project._id}`, {
                    data: {
                        userId: user._id,
                        webIp:process.env.REACT_APP_WEBIP

                    }
                });
            
                setShowMessageAlert(true);
                setAlertVarient('success');
                setAlertMessage("Project Deleted Successfully");
                await delay(3000);
                const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/user/${user._id}`);

                const projectsWithBlank = [{}, ...response.data];

                setProjects(projectsWithBlank);
   
              
                
            } catch (error) {
                console.error('Error deleting project:', error);
            }

        }else{
            setShowMessage(true);
            setAlertVarient('warning');
            setAlertMessage("Message");
        }
    }

    return (
        <Container>



<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessageAlert(false)} show={showMessageAlert} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>


<Modal  show={showMessage} onHide={() => setShowMessage(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <h6>
Project deletion is disabled with an active subscription. Please cancel the subscription first.</h6>
                    </div>
                        <br/>
                     
                </Modal.Body>
            </Modal>



            {!user.trialDone ? (
                <Card  style={{
                    minHeight: '300px', // Adjust height as needed
                    width: '100%', // Full width of the Col
                    maxWidth: '250px', // Maximum width
                    borderRadius: '12px',
                    background:"#161616",
                    borderColor:"#fff",
                    cursor: 'pointer'
                }}>
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Card.Title style={{ color: 'white' }}>Free Trial</Card.Title>
                    <Card.Text style={{ color: 'white' }}>
                        Click below to start your free trial.
                    </Card.Text>
                    <Button onClick={handleFreeTrialProject} variant="primary">Activate Free Trial</Button>
                </Card.Body>
            </Card>
           
            ) : (
                <>
                
                    <div className="project-list">
    {Array.from({ length: Math.ceil(projects.length / 4) }, (row, rowIndex) => (
        <>
        
        
        <Row className='mb-3 custom-gx-10' key={rowIndex} style={{marginRight:'-7%'}}>
    {/* Loop through projects in the current row */}
    {projects.slice(rowIndex * 4, rowIndex * 4 + 4).map((project) => (
        
       <>

            {project.name == undefined?(
  <Col key={project._id} md={3} sm={6} className="mb-3">
<Card style={{
                            minHeight: '300px', // Adjust height as needed
                            width: '100%', // Full width of the Col
                            maxWidth: '250px', // Maximum width
                            backgroundColor: '#161616',
                            borderRadius: '12px',
                            borderColor:"#fff",
                            cursor: 'pointer',
                            textAlign:'start'
                        }}>
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Card.Title style={{ color: 'white' }}>New Project</Card.Title>
                    <Card.Text style={{ color: 'white'}}>
                        Click below to create a new project.
                    </Card.Text>
                    <Button onClick={handleNewProject} variant="primary">Create Project</Button>
                </Card.Body>
            </Card>

  </Col>

            ):(
                <Col key={project._id} md={3} sm={6} className="mb-3">
            <Card
                onClick={() => viewItem(project._id)}
                style={{
                    minHeight: '300px',
                    width: '100%', // Adjusted to take full width of the column
                    maxWidth: '250px', // Maximum width, can be adjusted
                    background: "#161616",
                    borderColor: "#fff",
                    borderRadius: '12px',
                    cursor: 'pointer',
                    textAlign:'start'
                }}
            >
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <Card.Title style={{ color: 'white', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <Row  alignItems="center">
                        <Col md ={6}>
                        <p>{project.name}</p>

                        </Col>

                        <Col md ={{span:'2', offset:'4'}}>
                        <MdOutlineDeleteOutline  
                        onClick={(e) => {
                        e.stopPropagation();
                        deleteItem(project);
                        }}/>

                        </Col>
                        
                    </Row>
                        
                    </Card.Title>
                    <Card.Text style={{ color: 'white' }}>
                        {project.status ? (
                            <>
                                Status: <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green', marginRight: '5px' }}></span> Active
                            </>
                        ) : (
                            <>
                                Status: <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red', marginRight: '5px' }}></span> Inactive
                            </>
                        )}
                    </Card.Text>
                    <Card.Text style={{ color: 'white' }}>
                        Max CCU: {project.allowedUsers}
                    </Card.Text>
                    <Button variant="primary">View Details</Button>
                </Card.Body>
            </Card>
            </Col>
                    )}
                    </>
      
    ))}
</Row>
        </>
    ))}
</div>       

                </>
            )}
        </Container>
    );
};

export default Dashboard;
