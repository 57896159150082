import React, { useEffect, useState } from 'react';
import { Button, Container, Stack } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from "react-icons/fc";
import MicrosoftLogin from "react-microsoft-login";
import { useDiscordLogin } from 'react-discord-login';
import { IoLogoDiscord } from "react-icons/io5";
import { BiLogoMicrosoft } from "react-icons/bi";
import mixpanel from '../mixpanel';
import API_BASE_URL from "../apiConfig";

const GoogleLoginComponent = ({ onLogin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUser = async()=>{
    const userId = localStorage.getItem('userId');
    const userToken = localStorage.getItem('userToken');
    if (userId && userToken) {
      const user = await axios.get(`${API_BASE_URL}/pixelStripeApi/users/${userId}`);

      const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

      const teamMember = teamUsers.data.data.filter(e=> e.email == user.data.email);
      onLogin(user.data);
      if(teamMember.length > 0){
        navigate('/dashboardUser');

      }else{
        navigate('/dashboard');

      }

    
    }
  }
    getUser()
  }, [navigate]);

  const handleSuccess = async (codeResponse) => {
    setLoading(true);


    const { code } = codeResponse;
    try {
      const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
        code,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        redirect_uri: window.location.origin,
        grant_type: 'authorization_code',
      });

      const { id_token } = tokenResponse.data;
      sessionStorage.setItem('userToken', id_token);
      localStorage.setItem('userToken', id_token);

      const userRes = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${id_token}`);
      const user = userRes.data;

      const { email, name, picture: photo, sub: googleId } = user;
      const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/users`, { email, name, photo, googleId });
      onLogin(response.data);
      sessionStorage.setItem('userId', response.data._id);
      localStorage.setItem('userId', response.data._id);


      mixpanel.identify(response.data._id);
 
mixpanel.people.set({ '$name': response.data.name,
                      '$email': response.data.email,
});

const userproject = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/user/${response.data._id}`)
  
console.log(userproject);

      if (userproject.data && userproject.data.length == 0) {
        mixpanel.track('User Logged In', {
          "UserId": response.data._id,
          "method": "Google",
          "accountType":"New"
        });

        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

      const teamMember = teamUsers.data.data.filter(e=> e.email == email);
      if(teamMember.length > 0){
        navigate('/dashboardUser');

      }else{
        navigate('/dashboard');

      }
       

      } else {
        mixpanel.track('User Logged In', {
          "UserId": response.data._id,
          "method": "Google",
          "accountType":"Existing"
        });

        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

        const teamMember = teamUsers.data.data.filter(e=> e.email == email);
        if(teamMember.length > 0){
          navigate('/dashboardUser');
  
        }else{
          navigate('/dashboard');
  
        }

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: (error) => console.error('Login Failed:', error),
    flow: 'auth-code',
  });

  const authHandler = async (err, data) => {
    if (data.accessToken) {
      sessionStorage.setItem('userToken', data.accessToken);
      localStorage.setItem('userToken', data.accessToken);

     

      const responseNew = await axios.post(`${API_BASE_URL}/pixelStripeApi/users`, {
        email: data.mail,
        name: data.displayName,
        picture: data.avatar,
        googleId: data.id,
      });

      onLogin(responseNew.data);
      sessionStorage.setItem('userId', responseNew.data._id);
      localStorage.setItem('userId', responseNew.data._id);

  
      mixpanel.identify(responseNew.data._id);
 
mixpanel.people.set({ '$name': responseNew.data.name,
                      '$email': responseNew.data.email,
});
      
      const userproject = await axios.get(`${API_BASE_URL}/projects/user/${responseNew.data._id}`)
  
      console.log(userproject);
      
            if (userproject.data && userproject.data.length == 0) {
        mixpanel.track('User Logged In', {
          "UserId": responseNew.data._id,
          "method": "Microsoft",
          "accountType":"New"
        });
       

        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

        const teamMember = teamUsers.data.data.filter(e=> e.email == responseNew.data.email);
        if(teamMember.length > 0){
          navigate('/dashboardUser');
  
        }else{
          navigate('/dashboard');
  
        }

      } else {

        mixpanel.track('User Logged In', {
          "UserId": responseNew.data._id,
          "method": "Microsoft",
          "accountType":"Existing"
        });

        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

        const teamMember = teamUsers.data.data.filter(e=> e.email == responseNew.data.email);
        if(teamMember.length > 0){
          navigate('/dashboardUser');
  
        }else{
          navigate('/dashboard');
  
        }

      }
    }
  };

  const discordLoginParams = {
    clientId: '1261505947365408818',
    redirectUri: 'https://dashboard.streampixel.io',
    responseType: 'token',
    scopes: ['identify', 'email'],
    onSuccess: async (response) => {
      sessionStorage.setItem('userToken', response.access_token);
      localStorage.setItem('userToken', response.access_token);

    
     
      const user = response.user;
      const responseNew = await axios.post(`${API_BASE_URL}/pixelStripeApi/users`, {
        email: user.email,
        name: user.username,
        picture: user.avatar,
        googleId: user.id,
      });

      onLogin(responseNew.data);
      sessionStorage.setItem('userId', responseNew.data._id);
      localStorage.setItem('userId', responseNew.data._id);

      mixpanel.identify(responseNew.data._id);
 
      mixpanel.people.set({ '$name': responseNew.data.name,
                            '$email': responseNew.data.email,
      });

      const userproject = await axios.get(`${API_BASE_URL}/projects/user/${responseNew.data._id}`)
  
      console.log(userproject);
      
            if (userproject.data && userproject.data.length == 0) {
        mixpanel.track('User Logged In', {
          "UserId": response.data._id,
          "method": "Discord",
          "accountType":"New"
        });
       
        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

        const teamMember = teamUsers.data.data.filter(e=> e.email == responseNew.data.email);
        if(teamMember.length > 0){
          navigate('/dashboardUser');
  
        }else{
          navigate('/dashboard');
  
        }


      } else {
        mixpanel.track('User Logged In', {
          "UserId": response.data._id,
          "method": "Discord",
          "accountType":"Existing"
        });
      
        const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);

        const teamMember = teamUsers.data.data.filter(e=> e.email == responseNew.data.email);
        if(teamMember.length > 0){
          navigate('/dashboardUser');
  
        }else{
          navigate('/dashboard');
  
        }

      }
      
    },
    onFailure: (error) => {
      console.error('Login failed:', error);
    },
  };

  const { buildUrl, isLoading } = useDiscordLogin(discordLoginParams);

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '18%' }}>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Loading...</p>
        </div>
      ) : (
        <Stack gap={3} style={{ maxWidth: '400px' }}>
          <Button size="lg" onClick={() => login()} style={{ background: "#444444", border: '1px solid #444444' }}>
            <FcGoogle size={'32px'} style={{ marginRight: '25px' }} />
            Continue with Google
          </Button>

         
<MicrosoftLogin   style={{maxWidth:"400px"}} withUserData={true} authParams={{ prompt: "select_account" }} children={

<Button size='lg' style={{background:"#444444", border:'1px solid #444444',width:"400px"}}>
  <Stack direction='horizontal' gap="3">
<BiLogoMicrosoft size={'32px'} style={{marginLeft:"56px"}}/>
&nbsp; Continue with Microsoft &nbsp; &nbsp;
 </Stack>
</Button>

} clientId={"631f6750-f5fe-41dd-9382-df21f6cb8cc5"} redirectUri='https://dashboard.streampixel.io' authCallback={authHandler} />


          <Button
            size="lg"
            onClick={() => (window.location.href = buildUrl())}
            disabled={isLoading}
            style={{ background: "#444444", border: '1px solid #444444' }}
          >
            <IoLogoDiscord size={'32px'} style={{ marginRight: '25px' }} />
            Continue with Discord
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default GoogleLoginComponent;
