import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import Select from 'react-select';
import API_BASE_URL from "../apiConfig";

const AddTeamMember = ({ user, onSuccess, selectedMember }) => {
  const [formData, setFormData] = useState({
    primaryUserId: user._id,
    email: '',
    role: 'READONLY',
    allowedProjects: [],
  });

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/projects/user/${user._id}`);
        setProjects(response.data);
      } catch (err) {
        setError('Failed to load projects');
      }
    };

    fetchProjects();
  }, [user._id]);

  useEffect(() => {
    if (selectedMember) {
      setFormData({
        primaryUserId: selectedMember.primaryUserId,
        email: selectedMember.email,
        role: selectedMember.role,
        allowedProjects: selectedMember.allowedProjects || [],
      });
    }
  }, [selectedMember]);

  const projectOptions = [
    { value: 'allProjects', label: 'All Projects' }, 
    ...projects.map((project) => ({
      value: project._id,
      label: project.name,
    })),
  ];

  const handleChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'allProjects')) {
      const allProjectIds = projects.map(project => project._id);
      setFormData((prev) => ({
        ...prev,
        allowedProjects: allProjectIds,
      }));
    } else {
      const selectedProjectIds = selectedOptions.map((option) => option.value);
      setFormData((prev) => ({
        ...prev,
        allowedProjects: selectedProjectIds,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      if (selectedMember) {
        console.log(formData);
        const response = await axios.put(
          `${API_BASE_URL}/pixelStripeApi/teamMember/edit/${selectedMember._id}`,
          formData
        );
      } else {
        const response = await axios.post(`${API_BASE_URL}/pixelStripeApi/teamMember/add`, formData);
      }
      setSuccess(true);
      setFormData({
        primaryUserId: user._id,
        email: '',
        role: 'READONLY',
        allowedProjects: [],
      });
      onSuccess(formData); 
    } catch (err) {
      setError('Failed to add/update team member. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={6}>
          {success && <Alert variant="success">Team member {selectedMember ? 'updated' : 'added'} successfully!</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={formData.role}
                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                required
              >
                <option value="READONLY">READONLY</option>
                <option value="FULL">FULL</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formProject">
              <Form.Label>Allowed Projects</Form.Label>
              <Select
                isMulti
                name="allowedProjects"
                options={projectOptions}
                value={projectOptions.filter((option) =>
                  formData.allowedProjects.includes(option.value)
                )}
                onChange={handleChange}
                placeholder="Select Projects"
                required
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'grey',
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: 'black',
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#444' : 'black',
                    color: state.isSelected ? 'white' : '#ddd',
                    ':hover': {
                      backgroundColor: '#333',
                      color: 'white',
                    },
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: '#444',
                    color: 'white',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: 'white',
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    color: 'white',
                    ':hover': {
                      backgroundColor: 'red',
                    },
                  }),
                }}
              />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : selectedMember ? 'Update Team Member' : 'Add Team Member'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddTeamMember;
