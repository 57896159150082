import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Body from './components/Body';
import GoogleLoginComponent from './components/GoogleLogin';
import UpdateAccountDetails from './components/UpdateAccountDetails';
import CreateProject from './components/createProject';
import ProjectDetail from './components/ProjectDetail';
import Dashboard from './components/Dashboard';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import CreateProjectTrial from './components/CreateProjectTrial';
import Admin from './components/Admin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import IntercomConnect from './components/IntercomConnect';
import API_BASE_URL from "./apiConfig";
import ManageTeam from './components/ManageTeam';
import DashboardUser from './components/DashboardUser';
import ProjectDetailUser from './components/ProjectDetailUser';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const paypalId = process.env.REACT_APP_PAYPAL_CLIENT_KEY;

const App = () => {
    const [user, setUser] = useState(null);
    const [userCountry, setUserCountry] = useState("EU");
    const [loading, setLoading] = useState(true);
    const [userIsTeam, setUserIsTeam] = useState(false);
    const [teamProjects, setTeamProjects] = useState([]);
    const [teamUserEdit, setTeamUserEdit] = useState(false);

    useEffect(() => {
        handleLogin();
    }, [user]);

    const handleLogin = async () => {
        const userId = localStorage.getItem('userId');

        try {
            const userRes = await axios.get(`${API_BASE_URL}/pixelStripeApi/users/${userId}`);
            setUser(userRes.data);

            const teamUsers = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view`);
            const teamMember = teamUsers.data.data.filter((e) => e.email === userRes.data.email);

            if (teamMember.length > 0) {
                const projectPromises = teamMember[0].allowedProjects.map((project) =>
                    axios.get(`${API_BASE_URL}/pixelStripeApi/projects/${project}`)
                );
                const projectDataArray = await Promise.all(projectPromises);
                setTeamProjects(projectDataArray.map((res) => res.data));
                setUserIsTeam(true);
                const userRole = teamMember[0].role;

                if(userRole !== "READONLY"){
                    setTeamUserEdit(true);
                }
                
            }

        } catch (error) {
            console.error('Error fetching user data:', error);
            // Optionally, display an error message to the user.
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <GoogleOAuthProvider clientId={clientId}>
                <Container fluid>
                    <Header user={user} userTeam={userIsTeam} setUser={setUser} />
                    <Body>
                        {userIsTeam ? (
                            <Routes>
                                <Route path="/dashboardUser" element={user ? <DashboardUser user={user} projects={teamProjects} setUser={setUser} /> : <Navigate to="/" />} />
                                <Route path="/projectDetailUser/:projectId" element={user ? <ProjectDetailUser user={user} canEdit={teamUserEdit} /> : <Navigate to="/" />} />
                            </Routes>
                        ) : (
                            <PayPalScriptProvider options={{ "client-id": paypalId, vault: true, currency: "USD" }}>
                                {user && <IntercomConnect user={user} />}
                                <Routes>
                                    <Route exact path="/" element={<GoogleLoginComponent onLogin={setUser} />} />
                                    <Route path="/account" element={user ? <UpdateAccountDetails user={user} setUser={setUser} /> : <Navigate to="/" />} />
                                    <Route path="/dashboard" element={user ? <Dashboard user={user} setUser={setUser} userCountry={userCountry} /> : <Navigate to="/" />} />
                                    <Route path="/createProject" element={user ? <CreateProject user={user} userCountry={userCountry} /> : <Navigate to="/" />} />
                                    <Route path="/manageTeam" element={user ? <ManageTeam user={user} /> : <Navigate to="/" />} />
                                    <Route path="/createProjectTrial" element={user ? <CreateProjectTrial user={user} userCountry={userCountry} /> : <Navigate to="/" />} />
                                    <Route path="/admin" element={user ? <Admin user={user} setUser={setUser} /> : <Navigate to="/" />} />
                                    <Route path="/projectDetail/:projectId" element={user ? <ProjectDetail user={user} userCountry={userCountry} /> : <Navigate to="/" />} />
                                </Routes>
                            </PayPalScriptProvider>
                        )}
                    </Body>
                    <Footer />
                </Container>
            </GoogleOAuthProvider>
        </Router>
    );
};

export default App;
