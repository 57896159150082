import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Row, Col, Container,Modal,Toast,ToastContainer} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDeleteOutline } from "react-icons/md";
import mixpanel from '../mixpanel';
import API_BASE_URL from "../apiConfig";

const DashboardUser = ({ user,projects }) => {
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [showMessageAlert, setShowMessageAlert] = useState(false);


    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );


   
    const viewItem = (itemId) => {
        mixpanel.track('Clicked Project Details Button', {
            "UserId": user._id,
            "Project Id":itemId,
            
          });


        navigate(`/projectDetailUser/${itemId}`);
    };

    
    return (
        <Container>



<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessageAlert(false)} show={showMessageAlert} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>




                <>
                
                    <div className="project-list">
   {Array.from({ length: Math.ceil(projects.length / 4) }, (row, rowIndex) => (
           <>
           
           
           <Row className='mb-3 custom-gx-10' key={rowIndex} style={{marginRight:'-7%'}}>
       
       {projects.slice(rowIndex * 4, rowIndex * 4 + 4).map((project) => (
           
     
        
    
 
     <Col key={project._id} md={3} sm={6} className="mb-3">
               <Card
                   onClick={() => viewItem(project._id)}
                   style={{
                       minHeight: '300px',
                       width: '100%', // Adjusted to take full width of the column
                       maxWidth: '250px', // Maximum width, can be adjusted
                       background: "#161616",
                       borderColor: "#fff",
                       borderRadius: '12px',
                       cursor: 'pointer',
                       textAlign:'start'
                   }}
               >
                   <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
   
                       <Card.Title style={{ color: 'white', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                       <Row  alignItems="center">
                           <Col md ={6}>
                           <p>{project.name}</p>
   
                           </Col>
   
                        
                           
                       </Row>
                           
                       </Card.Title>
                       <Card.Text style={{ color: 'white' }}>
                           {project.status ? (
                               <>
                                   Status: <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green', marginRight: '5px' }}></span> Active
                               </>
                           ) : (
                               <>
                                   Status: <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red', marginRight: '5px' }}></span> Inactive
                               </>
                           )}
                       </Card.Text>
                       <Card.Text style={{ color: 'white' }}>
                           Max CCU: {project.allowedUsers}
                       </Card.Text>
                       <Button variant="primary">View Details</Button>
                   </Card.Body>
               </Card>
               </Col>
   ))}
               </Row>
               </>))}
                     </div>       

                </>
        </Container>
    );
};

export default DashboardUser;
