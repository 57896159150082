import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Modal, Alert, Toast, ToastContainer } from 'react-bootstrap';
import AddTeamMember from './AddTeamMember';
import API_BASE_URL from "../apiConfig";
import { MdOutlineDeleteOutline, MdEdit } from "react-icons/md";

const ManageTeam = ({ user }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState();
  const [alertVarient, setAlertVarient] = useState();
  const [showMessageAlert, setShowMessageAlert] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null); 

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view/${user._id}`);
        setTeamMembers(response.data.data);
      } catch (err) {
        setError('Failed to fetch team members');
      }
    };

    fetchTeamMembers();
  }, [success, user._id]);

  const handleAddTeamMember = (newMember) => {
    setSuccess(true);
    setShowModal(false);
    window.location.reload(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null); 
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const deletedTeamMember = async (member) => {
    try {
      const res = await axios.delete(`${API_BASE_URL}/pixelStripeApi/teamMember/delete/${member._id}`, {
        data: {
          userId: user._id,
          webIp: process.env.REACT_APP_WEBIP,
        },
      });

      setShowMessageAlert(true);
      setAlertVarient('success');
      setAlertMessage("Team Member Deleted Successfully");
      await delay(3000);
      const response = await axios.get(`${API_BASE_URL}/pixelStripeApi/teamMember/view/${user._id}`);
      setTeamMembers(response.data.data);
    } catch (error) {
      console.error('Error deleting team member:', error);
    }
  };

  const handleEditTeamMember = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  return (
    <Container>
      <ToastContainer position={'bottom-end'}>
        <Toast
          bg={alertVarient}
          onClose={() => setShowMessageAlert(false)}
          show={showMessageAlert}
          delay={3000}
          autohide
        >
          <Toast.Header></Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Row className="mt-5">
        <Col>
          <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
            Add New Member
          </Button>
        </Col>
      </Row>

      {success && (
        <Alert variant="success" className="mb-3">
          Team member added successfully!
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mb-3">
          {error}
        </Alert>
      )}

      <Row>
        {teamMembers.length === 0 ? (
          <Col>No team members found.</Col>
        ) : (
          teamMembers.map((member) => (
            <Col md={4} key={member._id} className="mb-4">
              <Card className="team-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Card.Title></Card.Title>
                    </div>
                    <div>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="me-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          deletedTeamMember(member);
                        }}
                      >
                        <MdOutlineDeleteOutline />
                      </Button>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleEditTeamMember(member)}
                      >
                        <MdEdit />
                      </Button>
                    </div>
                  </div>
                  <Card.Text>Email: {member.email}</Card.Text>
                  <Card.Text>Role: {member.role}</Card.Text>
                  <Card.Text>
                    Allowed Projects:
                    <ul>
                      {member.allowedProjects.map((projectId) => (
                        <li key={projectId}>{projectId}</li>
                      ))}
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedMember ? 'Edit Team Member' : 'Add Team Member'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddTeamMember onSuccess={handleAddTeamMember} user={user} selectedMember={selectedMember} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageTeam;
